<template>
    <div class="user-nickname">
        <span class="body-text-secondary">@{{ userNickname }}</span>
    </div>
</template>

<script>
export default {
    name: "UserNickname",
    props: {
        userNickname: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.user-nickname {
    color: #98989F;

    @media(max-width: $sm) {
        font-size: 14px;
    }
}
</style>
